const UpdateOrderDelivered = {
  id: 'update_order_delivered',
  title: 'Update Order Delivered',
  datapath: ['daily_route_confirmed_has_address_orders'],
  fields: [
    {
      id: 'account_number',
      datapath: ['daily_route_confirmed_address_order_for_address', 0, 'account_has_addresses', 0, 'account_number'],
      title: 'Account Number',
      type: '',
    },
    {
      id: 'address_order_id',
      datapath: ['id'],
      title: 'Address Order ID',
      type: '',
    },
    {
      id: 'order_details_special',
      type: 'special',
      display: {
        id: 'order_details_',
        title: 'Order Details',
        datapath: ['daily_route_confirmed_address_order_has_orders'],
        parent: {
          id: 'id',
          datapath: ['id'],
          title: 'Order IDs',
          type: '',
        },
        child: {
          title: 'Order Tangibles',
          datapath: ['order_has_tangibles'],
          fields: [
            {
              id: 'quantity',
              datapath: ['has_tangibles', 'quantity'],
              title: 'Quantity',
              type: '',
            },
            {
              id: 'tangible_name',
              datapath: ['name'],
              title: 'Tangible\'s Name',
              type: '',
            },
          ],
        },
      },
      editable: {
        id: 'order_delivered_details',
        title: 'Order Delivered Details',
        datapath: ['daily_route_confirmed_address_order_has_orders'],
        parent: {
          id: 'order_delivered_ids',
          title: 'Order Delivered Details',
          datapath: ['order_delivered_for_order'],
          createText: 'OPEN',
          submitText: 'SUBMIT',
          editText: 'EDIT ORDER DELIVERED',
          class_name: 'OrderDelivered',
          paramInject: [
            {
              type: 'dateTimeWithSeconds',
              key: 'delivery_datetime',
              datapath: ['route_date'],
            },
            {
              key: 'order_delivered_by_driver',
              datapath: ['daily_route_confirmed_for_driver'],
            },
          ],
          fixedParamInject: [
            {
              param_datapath: ['OrderDelivered', 'DuringDailyRoute'],
              wrapSourceInArray: true,
              sourceType: 'data',
              source_datapath: [],
            },
            {
              param_datapath: ['OrderDelivered', 'ForOrder'],
              wrapSourceInArray: true,
              sourceType: 'special_row',
              source_datapath: [],
            },
            {
              param_datapath: ['OrderDelivered', 'IncludeTangibles'],
              wrapSourceInArray: false,
              sourceType: 'special_row',
              source_datapath: ['order_has_tangibles'],
            },
          ],
        },
        child: {
          title: 'Order Tangibles',
          ref_datapath: ['order_has_tangibles'],
          fields: [
            {
              id: 'quantity',
              datapath: ['include_tangibles', 'quantity'],
              ref_datapath: ['has_tangibles', 'quantity'],
              title: 'Quantity',
              type: 'integer',
            },
            {
              id: 'tangible_name',
              datapath: ['name'],
              title: 'Tangible\'s Name',
              type: 'display',
            },
          ],
        },
      },
    },
  ],
};

export default UpdateOrderDelivered;