<template>
  <div class="animated fadeIn">
    <div v-if="this.error_message">
      <b-card class="m-3">
        <span style="font-size:24px; font-weight: 400">{{ this.error_message }}</span>
      </b-card>
    </div>
    <Modal
      :_rel_search_components="_modal_rel_search_components"
      @close="onModalClose"
      @createSuccess="onCreateSuccess"
      @createFailed="onCreateFailed"
      ref="bulk_update_modal"
      :class_name="modal_class_name || 'OrderDelivered'"
      :fieldSetting="modal_fieldSetting || {}"
      :submitId="submitId"
      :fixed_params.sync="modal_fixed_params"
      :_params="modal_params"
      :_read_params="_read_params"
      :_immutable="_immutable"
      :with_childs_mode="with_childs_mode"
      :manual_get="manual_get"
      :edit_button_label="edit_button_label"
    >
      <template v-for="slotName in class_relationships_slotNames" v-slot:[slotName]="{ relationship, disabled }">
        <slot :name="slotName" :relationship="relationship" :disabled="disabled"></slot>
      </template>
      <template v-slot:modal_above_tabs="modal_above_tabs_props">
        <slot name="modal_above_tabs" v-bind="modal_above_tabs_props"></slot>
      </template>
      <template v-slot:modal_below_tabs="modal_below_tabs_props">
        <slot name="modal_below_tabs" v-bind="modal_below_tabs_props"></slot>
      </template>
      <template v-slot:modal_bottom="modal_bottom_props">
        <slot name="modal_bottom" v-bind="modal_bottom_props"></slot>
      </template>
      <template v-slot:modal_view="modal_view_props">
        <slot name="modal_view" v-bind="modal_view_props"></slot>
      </template>
    </Modal>
    <b-card class="daily_route_confirmed_detail">
      <div class="m-2" v-if="!this.$route.params['id']">
        <div>
          <div class="date-range-picker-for-drc mb-2">
            <VueCtkDateTimePicker
              id="start_date_for_drc"
              class="mr-1"
              v-model="start_date_for_drc"
              :only-date="true"
              :format="this.$d.date_format"
              :output-format="this.$d.date_format"
              :no-value-to-custom-elem="true"
              :auto-close="true"
            >
              <div>
                <b-input-group>
                  <b-input-group-prepend>
                    <slot name="prepend">Route Date Start:</slot>
                  </b-input-group-prepend>
                  <b-form-input
                    type="text"
                    :placeholder="this.$d.date_format"
                    :value="start_date_for_drc"
                    @input="this.setStartDateForDRC"
                  ></b-form-input>
                  <b-input-group-append>
                    <slot name="append"></slot>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </VueCtkDateTimePicker>
            <VueCtkDateTimePicker
              id="end_date_for_drc"
              class="mr-2"
              v-model="end_date_for_drc"
              :only-date="true"
              :format="this.$d.date_format"
              :output-format="this.$d.date_format"
              :no-value-to-custom-elem="true"
              :auto-close="true"
            >
              <div>
                <b-input-group>
                  <b-input-group-prepend>
                    <slot name="prepend">End:</slot>
                  </b-input-group-prepend>
                  <b-form-input
                    type="text"
                    :placeholder="this.$d.date_format"
                    :value="end_date_for_drc"
                    @input="this.setEndDateForDRC"
                  ></b-form-input>
                  <b-input-group-append>
                    <slot name="append"></slot>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </VueCtkDateTimePicker>
            <button
              class="btn btn-primary"
              @click="searchDrcBaseOnDateRange()"
            >Search DRC</button>
          </div>
          <div v-if="is_class_Loading.DailyRouteConfirmed">Loading...</div>
          <div class="drc-select-dropdown mb-2" v-else-if="this.descriptor_data.DailyRouteConfirmed.length">
            <multiselect
              class="mr-2"
              v-if="!is_class_Loading.DailyRouteConfirmed && this.descriptor_data.DailyRouteConfirmed.length"
              v-model="selected_drc"
              :options="this.descriptor_data.DailyRouteConfirmed"
              :custom-label="({ id, route_date, using_lorry }) => `${id} [${route_date}] ${using_lorry}`"
              placeholder="Select one"
              track-by="label"
            ></multiselect>
            <button
              class="btn btn-primary"
              @click="init()"
            >Get Details for DRC</button>
          </div>
        </div>
      </div>
      <div v-if="loading" class="update-loader-overlay">Updating...</div>
      <div v-if="item_id && data.id && !is_class_Loading.DailyRouteConfirmed">
        <div v-if="error_message" v-html="error_message" class="alert alert-danger"></div>
        <div v-if="success_message" v-html="success_message" class="alert alert-success"></div>
        <q-linear-progress class="progress-bar" color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
        <div>
          <div>
            <PropertiesView
              :properties="$d.getProperties('DailyRouteConfirmed')"
              :params.sync="data"
              :disabled="true"
            ></PropertiesView>
            <hr />
            <table>
              <tr
                v-for="(info) in detailInfo" :key="info.id || info.title" :title="info.title"
                style="height: 32px"
              >
                <td class="info-title">{{ info.title }}</td>
                <td class="pl-1 pr-2">:</td>
                <td><input style="width: 500px;" :value="info.value" :disabled="info.disabled" :type="info.type" /></td>
              </tr>
            </table>

            <hr />

            <div class="bulk-update-component">
              <div class="bulk-update-table-container mb-3" v-for="(tableSetting) in tableSettingArray" :key="tableSetting.id">
                <div class="bulk-update-table-loader" v-if="!dataChecking(data, tableSetting.datapath, 'length')">Loading table...</div>
                <div class="bulk-update-table-wrapper" v-else>
                  <h2 v-if="tableSetting.title">{{ tableSetting.title }}</h2>
                  <div class="bulk-update-table" :ref="'bulk_update_table-' + tableSetting.id">
                    <div class="bulk-update-header">
                      <div
                        :class="'cell ' + field.type + ' ' + (field.createOnly ? 'create-only' : '')"
                        v-for="(field) in tableSetting.fields"
                        :key="field.id"
                        :title="field.title"
                      >
                        <div v-if="field.type === 'special'" class="special-container">
                          <div class="cell special_display-type p-0">
                            <div class="border-bottom-black px-1">{{ field.display && field.display.title }}</div>
                            <div class="special_display-container p-0">
                              <div class="special_display-parent px-1 border-right-black">
                                {{ field.display && field.display.parent && field.display.parent.title }}
                              </div>
                              <div class="special_display-child px-1">
                                {{ field.display && field.display.child && field.display.child.title }}
                              </div>
                            </div>
                          </div>
                          <div :class="'cell special_editable-type p-0 ' + (field.createOnly ? 'create-only' : '')">
                            <div class="border-bottom-black px-1">{{ field.editable && field.editable.title }}</div>
                            <div class="special_editable-container p-0">
                              <div class="special_editable-parent px-1 border-right-black">
                                {{ field.editable && field.editable.parent && field.editable.parent.title }}
                              </div>
                              <div class="special_editable-child px-1">
                                {{ field.editable && field.editable.child && field.editable.child.title }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>{{ field.title }}</div>
                      </div>
                    </div>
                    <div
                      class="bulk-update-row-container"
                      v-for="(row) in (
                        tableSetting.deduplicateDatapath ?
                          dataChecking(data, tableSetting.datapath).filter(
                            (value, index, self) => (
                              self.findIndex((item) => dataChecking(item, tableSetting.deduplicateDatapath) === dataChecking(value, tableSetting.deduplicateDatapath))
                              === index
                            )
                          )
                          :
                          dataChecking(data, tableSetting.datapath)
                      )"
                      :key="row.id"
                    >
                      <div
                        class="bulk-update-row"
                        v-if="!tableSetting.hideRowIfLengthEmptyDatapath || dataChecking(row, [...tableSetting.hideRowIfLengthEmptyDatapath, 'length'])"
                      >
                        <div :class="'cell ' + field.type + ' ' +  (field.createOnly ? 'create-only' : '')" v-for="(field) in tableSetting.fields" :key="field.id">
                          <div class="special-type" v-if="field.type === 'special'">
                            <div class="special-container" v-for="(special_row) in (field.display.datapath ? dataChecking(row, field.display.datapath) : [row])" :key="special_row.id">
                              <div class="special_display-type" v-if="field.display">
                                <div class="special_display-container">
                                  <div class="special_display-parent cell">
                                    {{ dataChecking(special_row, field.display.parent.datapath) }}
                                  </div>
                                  <div class="special_display-child">
                                    <div class="special_display-child-row cell" v-for="(special_display_child_row) in dataChecking(special_row, field.display.child.datapath)" :key="special_display_child_row.id">
                                      <div class="special_display-child-field-row cell" v-for="(special_display_child_field) in field.display.child.fields" :key="special_display_child_field.id">
                                        {{ special_display_child_field.fixed_value ? special_display_child_row[`_fixed-${special_display_child_field.id}`] : dataChecking(special_display_child_row, special_display_child_field.datapath) }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="field.editable" :class="'special_editable-type ' + (field.createOnly ? 'create-only' : '') + ' ' + (failedList[`${field.editable.id}_for_${special_row.id}`] ? 'submit-failed' : '')">
                                <div class="loading-overlay" v-if="directSubmitList[`${field.editable.id}_for_${special_row.id}`]" />
                                <div class="special_editable-container">
                                  <div :class="'special_editable-parent cell ' + (dataChecking(special_row, [...field.editable.parent.datapath, 0]) ? 'submitted' : 'new')">
                                    <div v-if="!field.createOnly && dataChecking(special_row, [...field.editable.parent.datapath, 0])">
                                      <div class="special_editable-parent-edit" v-for="(parentItem) in dataChecking(special_row, field.editable.parent.datapath)" :key="parentItem.id">
                                        <button class="btn btn-primary" @click="onEditableActionClick({ data: parentItem, params: { ...field.editable.parent, fieldSetting: field } })">
                                          {{ field.editable.parent.editText || 'Edit' }} {{ parentItem.id }}
                                        </button>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <button class="btn btn-primary" @click="onEditableActionClick({ params: { ...field.editable.parent, row, special_row, fieldSetting: field.editable, fieldSetting: field } })">
                                        {{ field.editable.parent.createText || 'Create' }}
                                      </button>

                                      <button
                                        class="btn btn-primary should-trigger-by-submit-all"
                                        @click="onEditableActionClick({ params: { ...field.editable.parent, row, special_row, fieldSetting: field.editable, directSubmit: true, submitType: field.submitType, id: `${field.editable.id}_for_${special_row.id}`, fieldSetting: field } })"
                                        :disabled="directSubmitList[`${field.editable.id}_for_${special_row.id}`]"
                                      >
                                        <div v-if="directSubmitList[`${field.editable.id}_for_${special_row.id}`]">Submitting...</div>
                                        <div v-else>{{ field.editable.parent.submitText || 'Submit' }}</div>
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    class="special_editable-child"
                                    v-if="field.editable.child && (field.createOnly || !dataChecking(special_row, [...field.editable.parent.datapath, 0]))"
                                  >
                                    <div class="special_editable-child-row cell" v-for="(special_editable_child_row) in dataChecking(special_row, field.editable.child.ref_datapath)" :key="special_editable_child_row.id">
                                      <div :class="'cell ' + special_editable_child_field.type + '-type'" v-for="(special_editable_child_field) in field.editable.child.fields" :key="special_editable_child_field.id">
                                        <div v-if="special_editable_child_field.type === 'integer'">
                                          <input
                                            :value="dataChecking(special_editable_child_row, special_editable_child_field.datapath)"
                                            @change="onUpdateEditableChildInput($event, special_editable_child_row, special_editable_child_field.datapath)"
                                          />
                                        </div>
                                        <div v-else>
                                          {{ dataChecking(special_editable_child_row, special_editable_child_field.datapath) }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="error-message" v-html="failedList[`${field.editable.id}_for_${special_row.id}`]"></div>
                              </div>
                            </div>
                          </div>
                          <div v-else>{{ dataChecking(row, field.datapath) || '-' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-primary" @click="onSubmitAll({ tableSetting })">
                    Submit All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';
import PropertiesView from '@/descriptor/coreui/components/modal/PropertiesView';
import { QLinearProgress } from 'quasar';
import _ from 'lodash';
import { dataChecking, updateDataByPath } from '@/descriptor/coreui/globalUtils';
import UpdateOrderDelivered from './BulkUpdateConfig/UpdateOrderDelivered';
import UpdateOrderProductQuantity from './BulkUpdateConfig/UpdateOrderProductQuantity';
import UpdateReturnableTangibleReceived from './BulkUpdateConfig/UpdateReturnableTangibleReceived';

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  components: {
    VueCtkDateTimePicker,
    Multiselect,
    QLinearProgress,
    Modal: () => import('@/descriptor/coreui/Modal'),
    PropertiesView,
  },
  props: {
    parent_data: {
      type: Object,
      default: null,
    },
    _modal_rel_search_components: {},
    fixed_params: {
      type: Object,
      default: null,
    },
    _read_params: {
      type: Object,
    },
    _immutable: {
      type: Boolean,
      default: false,
    },
    with_childs_mode: {
      //IMPORTANT TODO: this is a temporary solution, use isWithRelationshipsSatisfied instead
      type: Boolean,
      default: false,
    },
    manual_get: {},
    edit_button_label: {
      type: String,
      default: 'Edit',
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      loading: null,

      account_: null,
      data_from_API: null,
      descriptor_data: {
        DailyRouteConfirmed: [],
      },
      descriptor_error: {},
      is_class_Loading: {},

      modal_params: null,
      modal_class_name: '',
      modal_fieldSetting: {},
      submitId: '',
      modal_fixed_params: {},
      directSubmitList: {},
      failedList: {},

      start_date_for_drc: null,
      end_date_for_drc: null,
      selected_drc: '',
    };
  },
  computed: {
    item_id: {
      // getter
      get: function() {
        if (this.selected_drc) {
          return this.selected_drc.id;
        }

        if (this.$route.params['id']) {
          return this.$route.params['id'];
        }

        if (_.get(this.data, ['id'], null)) {
          return _.get(this.data, ['id'], null);
        }

        return this.item_id_;
      },
      // setter
      set: function(item_id) {
        this.item_id_ = item_id;
      },
    },
    tableSettingArray() {
      return [
        UpdateOrderDelivered,
        UpdateOrderProductQuantity,
        UpdateReturnableTangibleReceived,
      ];
    },
    detailInfo() {
      const data = this.data;

      return [
        {
          title: 'From Warehouse',
          value: data.from_warehouse,
        },
        {
          title: 'For Driver',
          value: data.for_driver,
        },
        {
          title: 'Using Lorry',
          value: data.using_lorry,
        },
      ];
    },
    class_relationships_slotNames() {
      return _.map(this.class_relationships, (relationship) => relationship['from']['class_name'] + '_' + relationship['relationship_name']);
    },
    data: {
      get: function() {
        return this.data_from_API || this.parent_data || {};
      },
      set: function(newValue) {
        this.data_from_API = newValue;
      },
    },
    specialEditableData: {
      get: function() {
        return this.specialEditableData_;
      },
      set: function(newValue) {
        this.specialEditableData_ = newValue;
      },
    },
  },
  created() {
    this.getDebounced = _.debounce(this.get, 500);
    this.init();
  },
  methods: {
    dataChecking,
    init() {
      if (this.item_id) {
        this.data_from_API = null;
        this.get();
      }
    },
    searchDrcBaseOnDateRange() {
      this.fireDescriptorAPI({
        cache_key: 'getDailyRouteConfirmed',
        request_type: 'index',
        class_name: 'DailyRouteConfirmed',
        request_body: {
          properties_filter: {
            route_date: {
              start: this.start_date_for_drc,
              end: this.end_date_for_drc,
            },
          },
        },
      });
    },
    setStartDateForDRC(input) {
      this.start_date_for_drc = input;
    },
    setEndDateForDRC(input) {
      this.end_date_for_drc = input;
    },
    fireDescriptorAPI({ cache_key, request_type = 'index', request_body = {}, class_name, onSuccess = null, onError = null }) {
      const scope = this;

      this.is_class_Loading[class_name] = true; //makes sure no error messages from previous
      this.$forceUpdate();

      this.$d.request(
        request_type,
        class_name,
        request_body,
        (response) => {
          if (onSuccess instanceof Function) {
            onSuccess({ response });
          } else if (request_type === 'index') {
            scope.$d.setCache(cache_key || `${request_type}-${class_name}`, response);

            scope.descriptor_data[class_name] = response;
            scope.descriptor_error[class_name] = null; //makes sure no error messages from previous
            scope.is_class_Loading[class_name] = null; //makes sure no error messages from previous

            scope.$forceUpdate();
          }
        },
        (error_message, cancelled) => {
          console.error(error_message);

          if (onError instanceof Function) {
            onError({ error_message, cancelled });
          } else {
            if (error_message) {
              scope.descriptor_error[class_name] = error_message;
            }

            if (cancelled) {
              //do nothign if cancelled
            } else if (!scope.descriptor_error[class_name]) {
              scope.descriptor_error[class_name] = scope.$api.defaultErrorMessage;
            }
          }
        },
        () => {
          scope.is_class_Loading[class_name] = false;
        }
      );
    },
    initiateSpecialEditableValue({ data }) {
      this.tableSettingArray.forEach((tableSetting) => {
        const targetData = dataChecking(data, tableSetting.datapath);
        tableSetting.fields.forEach((fieldSetting) => {
          let fieldSettingDisplayToPopulate = fieldSetting.display;
          if (fieldSettingDisplayToPopulate) {
            const displayChildSetting = fieldSettingDisplayToPopulate.child;

            targetData.forEach((row) => {
              const fieldRowData = dataChecking(row, fieldSettingDisplayToPopulate.datapath) || [];
              fieldRowData.forEach((parentRow) => {
                displayChildSetting.fields.forEach((childFieldSetting) => {
                  if (childFieldSetting.fixed_value) {
                    const parentRowData = dataChecking(parentRow, displayChildSetting.datapath) || [];
                    parentRowData.forEach((childRow) => {
                      childRow[`_fixed-${childFieldSetting.id}`] = dataChecking(childRow, ...childFieldSetting.datapath);
                    });
                  }
                });
              });
            });
          }

          let fieldSettingEditableToPopulate = fieldSetting.editable;
          if (fieldSettingEditableToPopulate && (fieldSetting.createOnly || (!dataChecking(data, fieldSettingEditableToPopulate.datapath)))) {
            const editableParentSetting = fieldSettingEditableToPopulate.parent;
            const editableChildSetting = fieldSettingEditableToPopulate.child;

            targetData.forEach((row) => {
              const fieldRowData = dataChecking(row, fieldSettingEditableToPopulate.datapath) || [];
              fieldRowData.forEach((parentRow) => {
                const parentRowData = dataChecking(parentRow, editableParentSetting.datapath) || [];
                if (!parentRowData.length) {
                  const ref_parentRow = dataChecking(parentRow, editableChildSetting.ref_datapath) || [];
                  // const ref_parentRow = _.cloneDeep(dataChecking(parentRow, editableChildSetting.ref_datapath) || []);

                  editableChildSetting.fields.forEach((childFieldSetting) => {
                    if (childFieldSetting.type !== 'display') {
                      ref_parentRow.forEach((ref_childRow) => {
                        const minValue = childFieldSetting.minValue;
                        let value = dataChecking(ref_childRow, childFieldSetting.ref_datapath);
                        if (typeof minValue === 'number' && value < minValue) {
                          value = minValue;
                        }

                        updateDataByPath(ref_childRow, value, childFieldSetting.datapath);
                      });
                    }
                  });
                }
              });
            });
          }
        });
      });
    },
    get(successCallBack = null) {
      if (this.item_id) {
        this.loading = true;

        this.$d.request(
          'read',
          'DailyRouteConfirmed',
          {
            id: this.item_id,
            index_withs: false,
            with_virtual_properties: ['day_of_week', 'using_lorry', 'for_driver', 'assistant_drivers', 'from_warehouse'],
            with_relationships: [
              {
                from_class_name: 'DailyRouteConfirmed',
                relationship_name: 'ForDriver',
              },
              {
                from_class_name: 'DailyRouteConfirmed',
                relationship_name: 'HasAddressOrders',
                with_relationships: [
                  {
                    from_class_name: 'DailyRouteConfirmedAddressOrder',
                    relationship_name: 'HasOrders',
                    with_relationships: [
                      {
                        from_class_name: 'Order',
                        relationship_name: 'HasTangibles',
                      },
                      {
                        from_class_name: 'Order',
                        relationship_name: 'IncludeProducts',
                        with_properties: ['name'],
                      },
                      {
                        from_class_name: 'Order',
                        relationship_name: 'DeliverTo',
                      },
                      {
                        from_class_name: 'OrderDelivered',
                        relationship_name: 'ForOrder',
                        direction: 'from',
                      },
                    ],
                  },
                  {
                    from_class_name: 'DailyRouteConfirmedAddressOrder',
                    relationship_name: 'ForAddress',
                    with_relationships: [
                      {
                        from_class_name: 'Account',
                        relationship_name: 'HasAddresses',
                        direction: 'from',
                        with_relationships: [
                          {
                            from_class_name: 'Account',
                            relationship_name: "HasReturnableTangibleSummary",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                from_class_name: 'DailyRouteConfirmed',
                relationship_name: 'IncludeOrders',
                with_relationships: [
                  {
                    from_class_name: 'Order', // TODO: only account included in order will appear, if Sales Return the wont appear here
                    relationship_name: 'UnderAccount',
                    with_relationships: [{
                      from_class_name: "Account", // TOOD: need to virtual relationship from DailyRouteConfirmed to Account
                      relationship_name: "HasReturnableTangibleSummary",
                      // with_relationships: [{
                      //   from_class_name: "ReturnableTangibleSummary", // TOOD: need to virtual relationship from DailyRouteConfirmed to Account
                      //   relationship_name: "IncludeTangibles",
                            // with_relationships: [{
                            //   from_class_name: "ReturnableTangibleSummary", // TOOD: need to virtual relationship from DailyRouteConfirmed to Account
                            //   relationship_name: "IncludeTangibles",
                            // }],
                      // }],
                    }],
                  },
                ],
              },
            ],
          },
          (class_data) => {
            try {
              this.$set(this, 'data_from_API', class_data);
              this.initiateSpecialEditableValue({ data: class_data });

              var daily_route_confirmed_include_orders = class_data['daily_route_confirmed_include_orders'];
              this.daily_route_confirmed_include_orders = daily_route_confirmed_include_orders;
              var daily_route_confirmed_include_sales_returns = class_data['daily_route_confirmed_include_sales_returns'];
              this.daily_route_confirmed_include_sales_returns = daily_route_confirmed_include_sales_returns;

              if (class_data['daily_route_confirmed_include_sales_returns'] && class_data['daily_route_confirmed_include_sales_returns'].length != 0) {
                // get sales returns
                var o, p;
                var sales_returns_tangibles_quantity = 0;
                var sales_returns_tangibles_name = '';

                // create storage object literal and key/value vars
                var storage3 = {};
                var key3, value3;
                for (o = 0; o < class_data['daily_route_confirmed_include_sales_returns'].length; o++) {
                  for (p = 0; p < class_data['daily_route_confirmed_include_sales_returns'][o]['sales_return_has_tangibles'].length; p++) {
                    sales_returns_tangibles_quantity =
                      class_data['daily_route_confirmed_include_sales_returns'][o]['sales_return_has_tangibles'][p]['has_tangibles']['quantity'];
                    sales_returns_tangibles_name = class_data['daily_route_confirmed_include_sales_returns'][o]['sales_return_has_tangibles'][p]['name'];

                    key3 = sales_returns_tangibles_name;
                    value3 = parseInt(sales_returns_tangibles_quantity);

                    // if the key exists in storage, add to it
                    if (key3 in storage3) {
                      storage3[key3].push(value3);
                    } else {
                      // if the key doesn't exist, create key and add to it
                      storage3[key3] = [];
                      storage3[key3].push(value3);
                    }
                  }
                }
              } else {
                // document.getElementById('sales_returns_total_tangibles').innerHTML += '<span>No Sales Return Records Available.</span>';
              }

              var k, l;
              var total_tangible_quantity = 0;
              var total_tangible_name = '';

              // create storage object literal and key/value vars
              var storage = {};
              var key, value;
              if (daily_route_confirmed_include_orders) {
                for (k = 0; k < daily_route_confirmed_include_orders.length; k++) {
                  if (daily_route_confirmed_include_orders[k]['order_has_tangibles']) {
                    for (l = 0; l < daily_route_confirmed_include_orders[k]['order_has_tangibles'].length; l++) {
                      total_tangible_quantity = daily_route_confirmed_include_orders[k]['order_has_tangibles'][l]['has_tangibles']['quantity'];
                      total_tangible_name = daily_route_confirmed_include_orders[k]['order_has_tangibles'][l]['name'];

                      key = total_tangible_name;
                      value = parseInt(total_tangible_quantity);

                      // if the key exists in storage, add to it
                      if (key in storage) {
                        storage[key].push(value);
                      } else {
                        // if the key doesn't exist, create key and add to it
                        storage[key] = [];
                        storage[key].push(value);
                      }
                    }
                  }
                }
              }

              //total dispensers
              var m, n;
              var total_dispenser_quantity = 0;
              var total_dispenser_name = '';

              // create storage object literal and key/value vars
              var storage2 = {};
              var key2, value2;
              if (daily_route_confirmed_include_orders) {
                for (m = 0; m < daily_route_confirmed_include_orders.length; m++) {
                  if (daily_route_confirmed_include_orders[m]['order_has_dispensers']) {
                    for (n = 0; n < daily_route_confirmed_include_orders[m]['order_has_dispensers'].length; n++) {
                      total_dispenser_quantity = daily_route_confirmed_include_orders[m]['order_has_dispensers'][n]['has_dispensers']['quantity'];
                      total_dispenser_name = daily_route_confirmed_include_orders[m]['order_has_dispensers'][n]['name'];

                      key2 = total_dispenser_name;
                      value2 = parseInt(total_dispenser_quantity);

                      // if the key exists in storage, add to it
                      if (key2 in storage2) {
                        storage2[key2].push(value2);
                      } else {
                        // if the key doesn't exist, create key and add to it
                        storage2[key2] = [];
                        storage2[key2].push(value2);
                      }
                    }
                  }
                }
              }
              this.loading = false;
              if (successCallBack instanceof Function) {
                successCallBack();
              }
            } catch (err) {
              console.warn(err);
              this.loading = false;
            }
          },
          (error_message, cancelled) => {
            this.loading = false;
            console.error(error_message);

            if (error_message) {
              this.error_message = error_message;
            }

            if (cancelled) {
              //do nothign if cancelled
            } else if (!this.error_message) {
              this.error_message = this.$api.defaultErrorMessage;
            }
          },
          () => {
            this.loading = false;
          }
        );
      } else {
        console.error('no id found, cannot read');
      }
    },
    async onSubmitAll({ tableSetting }) {
      let tableEl = this.$refs['bulk_update_table-' + tableSetting.id];
      if (tableEl instanceof Array) {
        tableEl = tableEl[0]
      }

      if (tableEl) {
        const submitButtonList = tableEl.getElementsByClassName('should-trigger-by-submit-all');
        if (submitButtonList.length) {
          for (let i = 0, l = submitButtonList.length; i < l; i++) {
            await new Promise(resolve => setTimeout(resolve, 700));
            if (submitButtonList[i].scrollIntoView) {
              submitButtonList[i].scrollIntoView()
            }

            submitButtonList[i].click();
          }
        } else {
          alert('No submit available for this table.');
        }
      } else {
        console.warn('bulk_update_table not found. Failed to submit all.');
      }
    },
    onEditableActionClick({ data, params: { row, special_row, fieldSetting, directSubmit = false, submitType = 'create', id, class_name, paramInject = [], fixedParamInject = [] }}) {
      this.modal_fieldSetting = fieldSetting;
      this.submitId = id;
      this.failedList[id] = '';

      const dataLayer = {
        data: this.data,
        row,
        special_row,
      }

      const modal_params = {};
      paramInject.forEach((injectSetting) => {
        const source = dataLayer[injectSetting.sourceType || 'data'];

        let value = dataChecking(source, injectSetting.datapath);
        if (injectSetting.type === 'dateTimeWithSeconds') {
          value = moment(dataChecking(this.data, injectSetting.datapath)).format('YYYY-MM-DD HH:mm:ss');
        }

        modal_params[injectSetting.key] = value;
      });

      const modal_fixed_params_object = {};
      const modal_fixed_params_array = [];
      fixedParamInject.forEach((injectSetting) => {
        const source = dataLayer[injectSetting.sourceType];

        let sourceData = dataChecking(source, injectSetting.source_datapath);
        if (injectSetting.ignoreEmpty) {
          sourceData = sourceData.filter((item) => {
            const value = dataChecking(item, injectSetting.datapath);

            if (injectSetting.ignoreEmpty instanceof Function) {
              return injectSetting.ignoreEmpty(value);
            }

            return value;
          });
        }

        let value = this.$d.relationFixedParams(...injectSetting.param_datapath, injectSetting.wrapSourceInArray ? [sourceData] : sourceData);

        modal_fixed_params_array.push(value);
        Object.keys(value).forEach((key) => {
          modal_fixed_params_object[key] = value[key];
        });
      });

      if (directSubmit) {
        if (id) {
          this.directSubmitList[id] = true;
        }

        this.fireDescriptorAPI({
          // cache_key: '',
          request_type: submitType,
          class_name: class_name,
          request_body: {
            _no_read: true,
            ...modal_params,
            ...modal_fixed_params_object,
          },
          onSuccess: ({ response }) => {
            this.onCreateSuccess({ responseData: response, fieldSetting, submitId: id });
          },
          onError: ({ error_message }) => {
            this.onCreateFailed({ submitId: id, message: error_message });
          }
        });
        return;
      }

      this.modal_class_name = class_name;
      this.modal_params = modal_params;
      this.modal_fixed_params = _.merge(...modal_fixed_params_array);

      if (data) {
        this.modal_params = data;

        setTimeout(() => {
          this.openModal();
        }, 0);

        return;
      }

      this.openModal();
    },
    openModal() {
      this.$refs['bulk_update_modal'].open();
      // this.$emit('onModalOpen');
    },
    onModalClose(mutated) {
      this.$emit('onModalClose', mutated);
      if (mutated) {
        this.get();
      }
    },
    // eslint-disable-next-line no-unused-vars
    onCreateSuccess({ responseData, fieldSetting, submitId }) {
      this.get(() => {
        this.directSubmitList[submitId] = false;
        setTimeout(() => {
          this.$forceUpdate();
        }, 0);
      });
    },
    onCreateFailed({ submitId, message }) {
      this.directSubmitList[submitId] = false;
      this.failedList[submitId] = message;
      setTimeout(() => {
        this.$forceUpdate();
      }, 0);
    },
    onUpdateEditableChildInput(event, data, datapath) {
      updateDataByPath(data, event.target.value, datapath);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';


.date-range-picker-for-drc,
.drc-select-dropdown {
  display: flex;

  .btn {
    white-space: nowrap;
  }

  .date-time-picker .input-group-prepend {
    align-items: center;
  }
}
.daily_route_confirmed_detail  {
  position: relative;
  min-height: 300px;

  .update-loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: RGBA(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 6vw;
  }

  h2 {
   font-size: 2em;
   line-height: 1em;
  }

  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .info-title {
    font-weight: bolder;
  }

  .tab_card {
    background: none;

    > .tabs {
      > .tab-content {
        margin: 0.5rem;
      }
    }
  }

  .bulk-update-table-loader {
    color: gray;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 1rem 1.5em;
  }

  .bulk-update-table-wrapper {
    margin: 5em 0;
  }

  .bulk-update-table {
    font-size: 90%;
    overflow-x: auto;
    margin-bottom: 0.75em;
    border-bottom: 1px solid black;

    .border-bottom-black { border-bottom: 1px solid black; }
    .border-right-black { border-right: 1px solid black; }

    .bulk-update-header .cell,
    .bulk-update-row .cell {
      &.special,
      &.special_display,
      &.special_editable {
        border-right: none;
        border-bottom: none;
        padding: unset;

        .cell {
          border: none;
        }

        .special_display-child-row,
        .special_editable-child-row {
          display: flex;
          background: none;
          border: 1px solid gray;
          background-color: RGBA(235, 235, 235, 0.3);
          border-radius: 3px;
          margin: 3px 5px;
          padding: 4px 0;
          // background-color: #FCFCFC;
        }
      }
    }


    .bulk-update-header {
      background-color: #EAEAEA;
    }

    .bulk-update-header,
    .bulk-update-row {
      display: inline-flex;
      border: none;
      border-right: 1px solid black;

      .cell {
        width: 150px;
        // background: rgb(50, 71, 0);
        // background: linear-gradient(162deg, rgba(0,212,255,0.2) 0%, rgba(4,213,156,0.2) 52%, rgba(0,71,50,0.2) 100%);
        border: 1px solid black;
        border-right-width: 0;
        border-bottom-width: 0;
        padding: 0.15em 0.3em;

        &.special {
          width: 650px;

          .special-container {
            display: inline-flex;

            &:not(:last-child) {
              border-bottom: 1px solid black;
            }
          }

          .special_display-type {
            width: 300px;
            border-right: 1px solid black;

            .special_display-container {
              display: inline-flex;
              padding: 3px;

              .special_display-parent {
                background: none;
                width: 75px;
              }

              .special_display-child {
                .special_display-child-row {
                  width: calc(225px - 15px);

                  .special_display-child-field-row:first-child {
                    text-align: center;
                  }

                  .cell {
                    width: 175px;
                    background: none;

                    &:first-child {
                      width: 50px;
                    }
                  }
                }
              }
            }
          }

          .special_editable-type {
            width: 350px;
            position: relative;

            .loading-overlay {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background-color: RGBA(0, 0, 0, 0.25);
              z-index: 1;
            }

            &.submit-failed {
              background-color: RGBA(200, 0, 0, 0.25);

              .error-message {
                padding: 0.5em;
                font-size: 85%;
              }
            }

            .special_editable-container {
              display: inline-flex;
              width: 100%;
              padding: 3px;

              .special_editable-parent {
                width: 100px;
                text-align: center;
                background: none;

                button {
                  width: 100%;
                  margin: 0.25em 0;
                }

                &.submitted {
                  text-align: center;
                  width: 350px;
                }

                .special_editable-parent-edit {
                  margin: 3px 6px;
                }
              }

              .special_editable-child {
                .special_editable-child-row {
                  width: calc(250px - 15px);

                  > .cell {
                    background: none;

                    &.display-type {
                      width: 175px;
                    }

                    &.integer-type {
                      width: 75px;

                      input {
                        text-align: center;
                        border: 1px solid black;
                        border-radius: 3px;
                        max-width: 100%;
                        padding: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
