const UpdateOrderProductQuantity = {
  id: 'update_order-product_quantity',
  title: 'Update Order Product Quantity',
  datapath: ['daily_route_confirmed_has_address_orders'], // DRC to account
  fields: [
    {
      id: 'account_number',
      datapath: ['daily_route_confirmed_address_order_for_address', 0, 'account_has_addresses', 0, 'account_number'],
      title: 'Account Number',
    },
    {
      id: 'address_order_id',
      datapath: ['id'],
      title: 'Address Order ID',
    },
    {
      id: 'order_details_special',
      type: 'special',
      createOnly: true,
      submitType: 'update',
      display: {
        id: 'order_details_',
        title: 'Order Details',
        datapath: ['daily_route_confirmed_address_order_has_orders'],
        parent: {
          id: 'id',
          datapath: ['id'],
          title: 'Order IDs',
          type: '',
        },
        child: {
          title: 'Order Products',
          datapath: ['order_include_products'],
          fields: [
            {
              id: 'quantity',
              datapath: ['include_products', 'quantity'],
              title: 'Quantity',
              type: '',
              fixed_value: true,
            },
            {
              id: 'product_name',
              datapath: ['name'],
              title: 'Product\'s Name',
              type: 'display',
            },
          ],
        },
      },
      editable: {
        id: 'after_edit',
        title: 'After edit',
        datapath: ['daily_route_confirmed_address_order_has_orders'],
        parent: {
          id: 'order_ids',
          title: 'After edit',
          datapath: ['order_include_products'],
          createText: 'OPEN',
          submitText: 'EDIT ORDER',
          editText: 'EDIT ORDER',
          class_name: 'Order',
          paramInject: [
            {
              sourceType: 'special_row',
              key: 'id',
              datapath: ['id'],
            },
            {
              sourceType: 'special_row',
              key: 'order_include_products',
              datapath: ['order_include_products'],
            },
          ],
        },
        child: {
          title: 'Order Products',
          datapath: ['order_include_products-bk'],
          ref_datapath: ['order_include_products'],
          fields: [
            {
              id: 'quantity',
              datapath: ['include_products', 'quantity'],
              title: 'Quantity',
              type: 'integer',
            },
            {
              id: 'product_name',
              datapath: ['name'],
              title: 'Product\'s Name',
              type: 'display',
            },
          ],
        },
      },
    },
  ],
};

export default UpdateOrderProductQuantity;